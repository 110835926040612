(function() {
  var getData, images, loadNaverMap;

  images = require('./img/*.png');

  loadNaverMap = function(data) {
    var center, d, detail, getClickHandler, hideMarker, index, infoContent, infoWindow, infoWindows, j, k, len, len1, map, mapOptions, marker, markers, onErrorGeolocation, onSuccessGeolocation, options, position, showMarker;
    map = new naver.maps.Map('map', mapOptions = {
      center: new naver.maps.LatLng(37.3595704, 127.105399),
      zoom: 10
    });
    markers = [];
    infoWindows = [];
    for (j = 0, len = data.length; j < len; j++) {
      d = data[j];
      position = new naver.maps.LatLng(d.position.lat, d.position.lng);
      marker = new naver.maps.Marker(options = {
        map: map,
        position: position,
        zIndex: 100
      });
      infoContent = '';
      for (detail in d.info) {
        infoContent += `<b>${detail}:</b> ${d.info[detail]} <br />`;
      }
      infoWindow = new naver.maps.InfoWindow(options = {
        content: `<div style="width:150px;padding:10px;">\n    ${infoContent}\n</div>`
      });
      markers.push(marker);
      infoWindows.push(infoWindow);
    }
    showMarker = function(map, marker) {
      if (marker.setMap()) {
        return;
      }
      return marker.setMap(map);
    };
    hideMarker = function(map, marker) {
      if (!marker.setMap()) {
        return;
      }
      return marker.setMap(null);
    };
    naver.maps.Event.addListener(map, 'idle', function() {
      var k, len1, mapBounds, results1;
      mapBounds = map.getBounds();
      results1 = [];
      for (k = 0, len1 = markers.length; k < len1; k++) {
        marker = markers[k];
        position = marker.getPosition();
        if (mapBounds.hasLatLng(position)) {
          results1.push(showMarker(map, marker));
        } else {
          results1.push(hideMarker(map, marker));
        }
      }
      return results1;
    });
    // Return an event handler storing the marker index
    // as a closure variable named seq
    getClickHandler = function(seq) {
      return function(e) {
        marker = markers[seq];
        infoWindow = infoWindows[seq];
        if (infoWindow.getMap()) {
          return infoWindow.close();
        } else {
          return infoWindow.open(map, marker);
        }
      };
    };
    for (index = k = 0, len1 = markers.length; k < len1; index = ++k) {
      marker = markers[index];
      naver.maps.Event.addListener(marker, 'click', getClickHandler(index));
    }
    onSuccessGeolocation = function(position) {
      var location;
      location = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
      map.setCenter(location);
      map.setZoom(10);
      marker = new naver.maps.Marker(options = {
        position: location,
        map: map,
        zIndex: 1000,
        icon: {
          content: `<img src='${images.pin_default}' alt='' ` + 'style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; ' + '-webkit-user-select: none; position: absolute; width: 22px; height: 35px; left: 0px; top: 0px;">',
          size: new naver.maps.Size(22, 35),
          anchor: new naver.maps.Point(11, 35)
        }
      });
      return console.log('Coordinates: ' + location.toString());
    };
    onErrorGeolocation = function() {
      var center;
      center = map.getCenter();
      infoWindow.setContent(`<div style="padding:20px;">\n    <h5 style="margin-bottom:5px;color:#f00;">Geolocation failed!</h5>\n    latitude:  ${center.lat()} <br />\n    longitude: ${center.lng()}\n</div>`);
      return infoWindow.open(map, center);
    };
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(onSuccessGeolocation, onErrorGeolocation);
    } else {
      center = map.getCenter();
      infoWindow.setContent('<div style="padding:20px;">\n    <h5 style="margin-bottom:5px;color:#f00;">\n        Geolocation not supported\n    </h5>\n</div>');
      return infoWindow.open(map, center);
    }
  };

  getData = async function() {
    var GCP_API_KEY, SPREADSHEET_ID, _, address, header, headers, i, index, info, j, json, k, lat, latLng, len, len1, lng, object, otherDetails, ref, response, results, url, value;
    SPREADSHEET_ID = process.env.SPREADSHEET_ID;
    GCP_API_KEY = process.env.GCP_API_KEY;
    url = 'https://sheets.googleapis.com/v4/spreadsheets/' + `${SPREADSHEET_ID}/values/get?range=A1:ZZ999999` + `&key=${GCP_API_KEY}`;
    response = (await fetch(url));
    json = (await response.json());
    results = [];
    headers = null;
    ref = json.values;
    for (index = j = 0, len = ref.length; j < len; index = ++j) {
      value = ref[index];
      if (index === 0) {
        [_, _, ...headers] = value;
        continue;
      }
      [latLng, address, ...otherDetails] = value;
      [lat, lng] = latLng.split(',');
      lat = parseFloat(lat);
      lng = parseFloat(lng);
      info = {};
      for (i = k = 0, len1 = headers.length; k < len1; i = ++k) {
        header = headers[i];
        info[header] = otherDetails[i] || 'n/a';
      }
      results.push(object = {
        position: {
          lat: lat,
          lng: lng
        },
        info: info
      });
    }
    return results;
  };

  document.addEventListener('DOMContentLoaded', async function() {
    var data;
    console.log(`Starting... env version ${process.env.VERSION}`);
    data = (await getData());
    return loadNaverMap(data);
  });

}).call(this);
